/**
 * Table styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { TableCellProps } from '@/components/Atoms/Table/Table';


export const TableCellContainer = styled.div<TableCellProps>`
    .SimpleCell {
        &,
        &__main {
            opacity: ${({ disabled }) => disabled ? '0.85' : 'initial'};
            cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} !important;
        }

        [data-cell-container="true"] {
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .vkuiText {
            font-size: var(--vkui--font_headline1--font_size--compact);
        }

        .vkuiCaption {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .SimpleCell__content {
            display: block !important;
        }

        .SimpleCell__main {
            cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
            padding: ${({ padding }) => padding ? padding : '10px 0'};
        }

        .SimpleCell__children > div {
            display: grid;
            text-align: left;
            align-items: center;
            gap: ${({ gap }) => gap || 0};
            grid-template-columns: ${({ grid }) => grid || `repeat(${grid}, minmax(0, 1fr))`};

            .SimpleCell__main {
                padding: 0;
            }
        }
    }
`;
