/**
 * Table component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';

import { SimpleCell, SimpleCellProps } from '@exode.ru/vkui';

import { TableCellContainer } from './Table.styled';


export interface TableWrapperProps {
    children: ReactNode;
    grid: string;
    gap?: string;
}

export interface TableCellProps extends SimpleCellProps {
    children: ReactElement | ReactElement[];
    grid?: string;
    gap?: string;
    color?: string;
    padding?: string;
}


/**
 * Table context
 * @type {React.Context<{cell: {grid: string}}>}
 */
const TableContext = createContext({
    cell: {
        grid: '',
        gap: undefined as string | undefined,
    },
});

/**
 * Table wrapper
 * @param {TableWrapperProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const Wrapper = (props: TableWrapperProps) => {

    const { grid, gap = '0.5rem' } = props;

    const getContextValue = () => ({
        cell: { gap, grid },
    });

    const [ context, setContext ] = useState(getContextValue());

    useEffect(() => {
        setContext(getContextValue);
    }, [ props ]);

    return (
        <TableContext.Provider value={context}>
            {props.children}
        </TableContext.Provider>
    );
};

/**
 * Table header
 * @param {TableCellProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const Header = (props: TableCellProps) => {

    const { children, ...rest } = props;

    const context = useContext(TableContext);

    return (
        <Table.Cell disabled
                    gap={context.cell.gap}
                    grid={context.cell.grid}
                    color="var(--text_primary)"
                    className="sticky top-header bg-content-50--blur z-[1]" {...rest}>
            {children}
        </Table.Cell>
    );
};

/**
 * Table cell
 * @param {TableCellProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const Cell = (props: TableCellProps) => {

    const { children, onClick, ...rest } = props;

    const context = useContext(TableContext);

    return (
        <TableCellContainer gap={context.cell.gap} grid={context.cell.grid}
                            {..._.omit(props, [ 'as', 'children', 'onClick' ])}>
            <SimpleCell onClick={onClick} {...rest}>
                <div data-cell-container="true" className="fs-15">
                    {children}
                </div>
            </SimpleCell>
        </TableCellContainer>
    );
};


const Table = {
    Wrapper,
    Header,
    Cell,
};


export { Table };
