/**
 * Link util
 * - переключение между истории по pages / modals / popups
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { PageParams, useRouter } from 'router.tsx';

import { observer, RouterStore } from '@/store/core/router';
import { ConfigStore } from '@/store/core/config';

import { RoutePathType } from '@/router/paths';

import { useRedirect } from '@/cutils';
import { ScrollHelper } from '@/helpers/ui';

import { ModalTypes } from '@/modals/index';

import { PageAction, Router } from '@/services/Utils/Router';


interface PopoutLinkAction {
    id: string;
    params?: PageParams;
}

interface PushReplacePage extends PageAction {
    x: number;
}

interface ModalAction {
    id: ModalTypes;
    params?: PageParams;
}

interface Props {
    children: ReactElement;
    backOrTo?: RoutePathType | undefined | string;
    pushPage?: PageAction;
    pushPageMobile?: PageAction;
    pushPageDesktop?: PageAction;
    replacePage?: PageAction;
    popPage?: boolean;
    popPageTo?: number | string;
    popPageToAndPush?: PushReplacePage;
    pushModal?: ModalAction;
    pushPopout?: PopoutLinkAction;
    replaceModal?: boolean;
    replacePopup?: boolean;
    disabled?: boolean;
    toOuter?: string;
    blank?: boolean;
    focus?: boolean;
    closeModal?: boolean;
    stopPropagation?: boolean;
    onClick?: (event: MouseEvent) => void;
}

export type LinkWrapperProps<T> = T;


const Link = observer((props: Props) => {

    const {
        children,
        backOrTo,
        pushPage,
        pushPageMobile,
        pushPageDesktop,
        replacePage,
        popPage,
        popPageTo,
        popPageToAndPush,
        pushModal,
        pushPopout,
        replaceModal,
        replacePopup,
        disabled,
        toOuter,
        blank,
        focus,
        closeModal,
        stopPropagation,
    } = props;

    const router = useRouter(false);

    const { isDesktop } = ConfigStore;

    const pushPagePath = !isDesktop && pushPageMobile
        || isDesktop && pushPageDesktop
        || pushPage;

    const path = pushPagePath?.id
        || backOrTo
        || replacePage?.id
        || popPageToAndPush?.id
        || pushModal?.id
        || pushPopout?.id;

    const onClick = (event: MouseEvent) => {
        event.preventDefault();

        props.onClick?.(event);

        if (disabled || stopPropagation) {
            event.stopPropagation();

            if (disabled) {
                return;
            }
        }

        if ((event.ctrlKey || event.metaKey) && pushPage) {
            return window.open(Router.compileLink(pushPage));
        }

        if (toOuter || pushPage?.id && blank) {
            const { redirect } = useRedirect();

            const link = toOuter || Router.compileLink(pushPage as PageAction);

            return redirect(link, blank, focus);
        }

        if (backOrTo) {
            router.backOrTo(backOrTo);

        } else if (pushPagePath) {
            /** Игнорируем текущую страницу */

            if (RouterStore.pageId === pushPagePath.id) {
                /** Сравниваем параметры страницы */

                if (Router.paramsIsEqual(pushPagePath.params)) {
                    return ScrollHelper.to(0, true);
                }
            }

            Router.pushPage(
                pushPagePath.id,
                Router.checkParams(pushPagePath.id, pushPagePath.params),
                closeModal,
            );

        } else if (replacePage) {
            Router.replacePage(replacePage.id, replacePage.params, closeModal);

        } else if (popPage) {
            router.popPage();

        } else if (popPageTo) {
            router.popPageTo(popPageTo);

        } else if (popPageToAndPush) {
            router.popPageToAndPush(
                popPageToAndPush.x,
                popPageToAndPush.id,
                Router.checkParams(popPageToAndPush.id, popPageToAndPush.params),
            );

        } else if (pushModal) {
            Router.pushModal(pushModal.id, pushModal.params);

        } else if (pushPopout) {
            Router.pushPopout(pushPopout.id, pushPopout.params);

        } else if (replaceModal) {
            Router.replaceModal();

        } else if (replacePopup) {
            Router.replacePopout();
        }

        if (_.isFunction(children.props?.onClick)) {
            children.props?.onClick();
        }
    };

    const cloneProps = {
        onClick,
        'data-link': true,
        'data-path': path,
        'data-disabled': disabled,
    };

    return React.cloneElement(children, cloneProps);
});


export { Link };
