/**
 * SlimMenuButtonIcon
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { PageParams } from 'router.tsx';
import { RoutePathType } from '@/router/paths';

import { Link, SvgComponent } from '@/cutils';

import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Counter, CounterProps, IconButton } from '@exode.ru/vkui';
import { Placement } from '@exode.ru/vkui/dist/components/Popper/Popper';


interface LinkProps {
    pushPage: {
        id: RoutePathType,
        params?: PageParams,
    },
}

export interface SlimMenuButtonIconProps {
    icon: ReactElement;
    tooltipText: string;
    type?: 'button' | 'avatar';
    linkProps?: LinkProps;
    placement?: Placement;
    hidden?: boolean;
    counter?: number | null;
    counterProps?: CounterProps;
}


const SlimMenuButtonIcon = (props: SlimMenuButtonIconProps) => {

    const {
        icon,
        counter,
        hidden,
        linkProps,
        counterProps,
        tooltipText,
        type = 'button',
        placement = 'left',
    } = props;

    if (hidden) {
        return <></>;
    }

    return (
        <Link {...linkProps}>
            <div className={type === 'avatar' ? 'flex justify-center' : ''}>
                <TextTooltip hidden={!tooltipText} placement={placement} text={tooltipText}>
                    <IconButton className="vk-rounded p-0">
                        {icon && <SvgComponent element={icon} svgProps={{ fill: 'var(--accent)' }}/>}

                        {counter ? (
                            <Counter size="s" mode="primary" className="absolute right-0 top-0" {...counterProps}>
                                {counter}
                            </Counter>
                        ) : null}
                    </IconButton>
                </TextTooltip>
            </div>
        </Link>
    );
};


export { SlimMenuButtonIcon };
