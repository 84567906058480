/**
 * FooterBlockItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { If, Responsive } from '@/cutils';
import { RoutePathType } from '@/router/paths';

import { Separator } from '@exode.ru/vkui';
import { Icon28ChevronDownOutline, Icon28ChevronUpOutline } from '@vkontakte/icons';

import { FooterTextItemPart } from './FooterTextItemPart';
import { FooterContactItemPart } from './FooterContactItemPart';


export interface FooterBlockItemBody {
    mode?: 'text' | 'contact';
    text?: string;
    link?: RoutePathType;
    onClick?: () => void;
    contact?: {
        name?: string;
        email?: string;
        role?: string;
        phone?: string;
    };
}

export interface FooterBlockItemProps {
    section: {
        title: string;
        items: FooterBlockItemBody[]
    };
}


const FooterBlockItemPart = (props: FooterBlockItemProps) => {

    const { section } = props;

    const [ isToggled, setIsToggled ] = useState(true);

    const children = section.items?.map((item, index) => {

        const { mode = 'text' } = item;

        return (
            <div key={index}>
                <If is={mode === 'text'}>
                    <FooterTextItemPart item={item}/>
                </If>

                <If is={mode === 'contact'}>
                    <FooterContactItemPart item={item}/>
                </If>
            </div>
        );
    });

    return (
        <div className="m:w-full">
            <div className="flex items-start flex-col gap-5 w-full">
                <Responsive.Desktop>
                    <>
                        <div className="flex justify-between w-full line-clamp-1">
                            <span className="fs-15 m:fs-16 font-vk-sans-display">
                                {section.title || ' '}
                            </span>
                        </div>

                        <Separator className="w-full mt-0" wide={true}/>

                        <>{children}</>
                    </>
                </Responsive.Desktop>

                <Responsive.Mobile>
                    <>
                        <If is={!!section.title}>
                            <div onClick={() => setIsToggled(!isToggled)}
                                 className="flex justify-between w-full cursor-pointer items-center">
                                <span className="fs-15 m:fs-16 m-0 font-vk-sans-display">
                                    {section.title}
                                </span>

                                {(
                                    !isToggled
                                        ? <Icon28ChevronDownOutline fill="var(--icon_secondary)"/>
                                        : <Icon28ChevronUpOutline fill="var(--icon_secondary)"/>
                                )}
                            </div>
                        </If>

                        <If is={isToggled}>
                            {children}
                        </If>
                    </>
                </Responsive.Mobile>
            </div>
        </div>
    );
};


export { FooterBlockItemPart };
