/**
 * SchoolMenuPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { observer, SchoolStore } from '@/store/platform';

import { useI18n } from '@/hooks/core';
import { Permission } from '@/codegen/graphql';
import { RoutePathType } from '@/router/paths';
import { Router } from '@/services/Utils/Router';
import { If, Link, Rbac, SvgComponent } from '@/cutils';

import { Icon24Settings } from '@vkontakte/icons';
import { Button, Separator, SimpleCell, SimpleCellProps } from '@exode.ru/vkui';


import { DynamicVkIcon } from '@/components/Atoms/DynamicVkIcon';


interface Props {
    simpleCellProps?: SimpleCellProps;
    iconSize?: number;
}


const SchoolMenuPart = observer((props: Props) => {

    const { simpleCellProps, iconSize = 24 } = props;

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    return (
        <>
            {SchoolStore.blocks?.leftMenu?.map(({ link, name, icon }, index) => {
                const match = Router.matchLinkPathAppRoute(link);

                return (
                    <Link key={index} blank={!match} toOuter={!match ? link : undefined} pushPage={(
                        match
                            ? { id: match.path as RoutePathType, params: match.params }
                            : undefined
                    )}>
                        <SimpleCell expandable before={(
                            <SvgComponent element={<DynamicVkIcon vkIconName={icon}/>} svgProps={{
                                width: iconSize,
                                height: iconSize,
                                className: 'text-white',
                            }}/>
                        )} {...simpleCellProps}>
                            {name}
                        </SimpleCell>
                    </Link>
                );
            })}

            <If is={_.isEmpty(SchoolStore.blocks?.leftMenu)}>
                <Rbac.Permission permissions={[ Permission.SchoolCustomizeSite ]}>
                    <Separator className="my-3"/>

                    <Link pushPage={{ id: '/manage/school/settings/left-menu' }}>
                        <Button stretched
                                size="m"
                                mode="tertiary"
                                className="bg-hover"
                                before={<Icon24Settings width={14} height={14}/>}>
                            <span className="fs-13 font-normal">
                                {t('setupLeftMenu')}
                            </span>
                        </Button>
                    </Link>
                </Rbac.Permission>
            </If>
        </>
    );
});


export { SchoolMenuPart };
