/**
 * AnswerModePart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactNode } from 'react';

import { Formik } from 'formik';

import { observer, Page } from '@/pages/Core';
import { SaveStoreDeepKeys, SaveStoreKeys, Saving } from '@/store/core/saving';

import { useI18n } from '@/hooks/core';
import { AutoSaveForm, If } from '@/cutils';

import { StorageSpace, TaskAnswerType } from '@/codegen/graphql';

import { Button } from '@exode.ru/vkui';
import { Icon24Done, Icon24PenOutline } from '@vkontakte/icons';

import { BlockTitle } from '@/components/Content/BlockTitle';
import { ContentElement } from '@/components/ContentElement';
import { TaskBuilderProps, useTaskBuilderContext } from '@/components/Task/Builder';
import { ArrangeShow, DetailedShow, MultipleShow, ShortShow } from '@/components/Task/Builder/show/types';

import { ShowModePartContainer } from '../TaskBuilder.styled';


interface Props extends TaskBuilderProps {
    header: ReactNode;
}


const ShowMode = observer((props: Props) => {

    const { task, header } = props;

    const { t } = useI18n('components.Task.Builder.parts.answer');

    const {
        edit,
        show: {
            error,
            answer,
            subMode,
            loading,
            saveMode,
            onAnswer,
            onInstantChange,
            answer: { userAnswer } = {},
        },
    } = useTaskBuilderContext();

    Saving.listenSetSaving(
        SaveStoreKeys.TaskShow,
        SaveStoreDeepKeys.TaskShowEdit,
        [ !!loading?.answer ],
        [ error?.answer ],
    );

    const components = {
        [TaskAnswerType.Arrange]: ArrangeShow,
        [TaskAnswerType.Boolean]: MultipleShow,
        [TaskAnswerType.Detailed]: DetailedShow,
        [TaskAnswerType.FillSpaces]: ArrangeShow,
        [TaskAnswerType.Mapping]: ShortShow,
        [TaskAnswerType.Multiple]: MultipleShow,
        [TaskAnswerType.MultipleWithAttachment]: MultipleShow,
        [TaskAnswerType.Short]: ShortShow,
        [TaskAnswerType.Single]: MultipleShow,
        [TaskAnswerType.SingleWithAttachment]: MultipleShow,
    };

    const ShowComponent = components[task.answerType];

    return (
        <ShowModePartContainer>
            <Page.Row innerNoVerticalPadding={0} innerNoHorizontalPadding={0}>
                <>{header}</>

                <div className={[
                    'flex flex-col flex-1 py-2 px-2',
                    subMode !== 'preview' ? 'pt-5 m:!px-0' : '',
                    subMode === 'preview' && !edit?.list?.expanded ? 'hidden' : '',
                ].join(' ')}>
                    <Page.Section className="px-4 pb-4 m:!px-0">
                        <Formik initialValues={{
                            quiz: userAnswer?.quiz || {},
                            detailed: userAnswer?.detailed || {},
                        }} onSubmit={(answer) => onAnswer?.({ ...answer, taskUuid: task.uuid })}>
                            {({ values, handleSubmit }) => (
                                <AutoSaveForm onSubmit={handleSubmit}
                                              onChange={handleSubmit}
                                              disabled={saveMode === 'button'}
                                              onInstantChange={() => onInstantChange?.()}
                                              delay={task?.answerType !== TaskAnswerType.Detailed ? 650 : 950}>
                                    <BlockTitle title={task.title} className="mb-2"/>

                                    {task.blocks.map((contentElement) => (
                                        <ContentElement mode="show"
                                                        key={contentElement.uuid}
                                                        contentElement={contentElement}
                                                        options={{ space: StorageSpace.Task }}/>
                                    ))}

                                    <div className="flex mb-2"/>

                                    <ShowComponent task={task}
                                                   result={answer?.result}
                                                   answer={_.pick(values, [ 'quiz', 'detailed' ])}/>

                                    <If is={saveMode === 'button'}>
                                        <div className="m:px-3">
                                            <Button size="l"
                                                    stretched
                                                    type="submit"
                                                    className="mt-2 m:mb-2"
                                                    loading={loading?.answer}
                                                    data-test="task.answer-button"
                                                    disabled={loading?.answer || !!userAnswer}
                                                    appearance={userAnswer ? 'positive' : 'accent'}
                                                    before={userAnswer ? <Icon24Done/> : <Icon24PenOutline/>}>
                                                {(
                                                    userAnswer
                                                        ? (userAnswer ? t('saved') : t('change'))
                                                        : t('answerBtn')
                                                )}
                                            </Button>
                                        </div>
                                    </If>
                                </AutoSaveForm>
                            )}
                        </Formik>
                    </Page.Section>
                </div>
            </Page.Row>
        </ShowModePartContainer>
    );
});


export { ShowMode };
