/**
 * Page meta info store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { observer } from 'mobx-react';
import { makeAutoObservable, observable } from 'mobx';

import { MetaRequestOptions } from '@/root/src/meta';

import { RouterStore } from '@/store/core/router';

import { ObjectUtil } from '@/utils';
import { ScrollHelper } from '@/helpers/ui';
import { MetaContent, MetaPropsType } from '@/types/page';

import { MetaPageService } from '@/services/Utils/Meta';
import { PageService } from '@/services/Utils/Page';


export type PageStructureModeType = 'regular' | 'fillable' | 'full-width' | 'slim-menu';
export type PageContextModeType = 'regular' | 'scrollable';

interface PanelHeaderStateProps {
    transparent: boolean;
    withShadow: boolean;
    visor: boolean;
}


class Page {

    constructor() {
        makeAutoObservable(this);
    }

    /** Тип страницы (с context menu в desktop, полная ширина и т.д.) */
    mode: PageStructureModeType = 'regular';

    /** Тип контекстного меню */
    contextMode: PageContextModeType = 'regular';

    /** Пустое пространство для контента */
    bottomSafeSpace: boolean = true;

    /** Высота экрана без ограничений */
    entireScreen: boolean = false;

    /** Мета теги текущей страницы */
    tags: MetaPropsType = {} as MetaPropsType;

    /** Состояние scroll в разрезе ключей страниц */
    scrolls: { [key: string]: number } = {};

    /** Блокировка закрытия модального окна */
    ignoreCloseModalCard: boolean = false;

    /** Состояние panel header */
    @observable
    panelHeader: PanelHeaderStateProps = {
        transparent: false,
        withShadow: false,
        visor: true,
    };

    /** Состояние показа footer */
    footer = true;

    /** Значение токена recaptcha */
    recaptchaToken = '';

    /**
     * Изменение типа структуры страницы
     * @param {boolean} mode
     */
    setPageMode(mode: PageStructureModeType = 'regular') {
        this.mode = mode;
    }

    /**
     * Изменение типа контекстного меню
     * @param {boolean} mode
     */
    setContextModeType(mode: PageContextModeType) {
        this.contextMode = mode;
    }

    /** Изменение состояния panel header */
    setPanelHeader(props: Partial<PanelHeaderStateProps>) {
        this.panelHeader = Object.assign(this.panelHeader, props);
    }

    /**
     * Установка мета тегов в разрезе переданных свойств (meta name)
     * @param {Partial<MetaPropsType>} tags
     */
    setTags(tags: Partial<MetaPropsType>) {
        this.tags = { ...this.tags, ...tags };
    }

    /**
     * Обновление мета тегов в соответствии с route pageId / params
     */
    async updateTags(options?: MetaRequestOptions) {
        const { params, pageId } = RouterStore;
        const { pathname, search } = window.location;

        const qs = search.replace(/\?/g, '');

        const tags = await MetaPageService.getMeta(pageId, pathname, qs, params, options);

        const tillIsSamePage = (() => {
            const { canonicalParams = [] } = MetaContent.pages[pageId];

            const pageIsSame = RouterStore.pageId === pageId;

            const paramsIsSame = ObjectUtil.isEqual(
                _.pick(params, canonicalParams),
                _.pick(RouterStore.params, canonicalParams),
            );

            return pageIsSame && paramsIsSame;
        })();

        if (tillIsSamePage) {
            this.tags = tags;

            PageService.onRouteTabChange(tags);
        }
    }

    /**
     * Получение data-page-mode значения
     */
    get dataPageMode() {
        return [ 'slim-menu' ].includes(this.mode) ? this.mode : 'regular';
    }

    /**
     * Сохранение скролла страницы
     * @param {string} key
     * @param {number} scrollTop
     */
    setScroll(
        key: string,
        scrollTop: number = ScrollHelper.scrollTop,
    ) {
        this.scrolls[key] = scrollTop;
    }

    merge(partial: Partial<this>) {
        Object.assign(this, partial);
    }

    /**
     * Установка временного блока модального окна
     * @param {boolean} ignore
     * @returns {void}
     */
    setIgnoreCloseModalCard(ignore: boolean) {
        this.ignoreCloseModalCard = ignore;
    }

}

const PageStore = new Page();


export { observer, PageStore };
